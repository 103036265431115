import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  lang: string = '';
  constructor() {
  }

  ngOnInit(): void {
    this.lang = localStorage.getItem('lang') || 'az';
  }
  changeLanguage(lang: string) {
    console.log(lang);
    localStorage.setItem('lang', lang);
    window.location.reload();
  }

}
