import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { TextModel } from "../models/text_model";

@Injectable({
    providedIn: 'root'
  })
export class DjangoService {
    private url = 'http://api.dilci.az/';
    constructor(private http: HttpClient) {

    }

    sendText(data: TextModel, analiz:string): Observable<TextModel> {
        return this.http.post<TextModel>(`${this.url}${analiz}`, JSON.stringify(data));
    }
}
