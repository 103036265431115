import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(public translate: TranslateService) {
    this.translate.setDefaultLang('az');
    this.translate.use(localStorage.getItem('lang') || 'az');
  }
  ngOnInit(){
  }
}
