import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TextModel } from '../core/models/text_model';
import { DjangoService } from '../core/services/django_service.services';
import { Table } from 'primeng/table';
import { PrimeNGConfig } from 'primeng/api';
@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.css']
})
export class LanguageComponent implements OnInit {

  @ViewChild('dt')
  table!: Table;
  @Input() name: string = '';
  confUrl: string = 'http://api.dilci.az';
  soz: any;
  metn: any;
  metn_result: any = '';
  soz_analizi: any;
  soz_result: any = '';
  luget: any;
  submitted:boolean= false;
  SELECTED_NITQ: string = '';
  lang: string = '';
  nitq = [
    {code: 'Isim', name: "NOUN"},
    {code: 'Sifet', name: "ADJECTIVE"},
    {code: 'Feil', name: "VERB"},
    {code: 'Say', name: "NUMBER"},
    {code: 'Zerf', name: "ADVERB"},
    {code: 'Evezlik', name: "PRONOUN"},
  ];

  COLS_CONFIG = {
    Isim: [
      {field: "soz", header: "Söz"},
      {field: "cem", header: "Cəm şəkilçisi"},
      {field: "hal", header: "Hal şəkilçisi"},
      {field: "mensubiyyet", header: "Mənsubiyyət şəkilçisi"},
      {field: "sexs", header: "Şəxs şəkilçisi"},
      {field: "hissecik", header: "Hissəcik"},
      {field: "izah", header: "Sözün izahı"}
    ],
    Sifet: [
      {field: "soz", header: "Söz"},
      {field: "cem", header: "Cəm şəkilçisi"},
      {field: "hal", header: "Hal şəkilçisi"},
      {field: "mensubiyyet", header: "Mənsubiyyət şəkilçisi"},
      {field: "sexs", header: "Şəxs şəkilçisi"},
      {field: "hissecik", header: "Hissəcik"},
      {field: "izah", header: "Sözün izahı"}
    ],
    Say: [
      {field: "soz", header: "Söz"},
      {field: "cem", header: "Cəm şəkilçisi"},
      {field: "hal", header: "Hal şəkilçisi"},
      {field: "mensubiyyet", header: "Mənsubiyyət şəkilçisi"},
      {field: "sexs", header: "Şəxs şəkilçisi"},
      {field: "hissecik", header: "Hissəcik"},
      {field: "izah", header: "Sözün izahı"}
    ],
    Evezlik: [
      {field: "soz", header: "Söz"},
      {field: "cem", header: "Cəm şəkilçisi"},
      {field: "hal", header: "Hal şəkilçisi"},
      {field: "mensubiyyet", header: "Mənsubiyyət şəkilçisi"},
      {field: "sexs", header: "Şəxs şəkilçisi"},
      {field: "hissecik", header: "Hissəcik"},
      {field: "izah", header: "Sözün izahı"}
    ],
    Zerf: [
      {field: "soz", header: "Söz"},
      {field: "cem", header: "Cəm şəkilçisi"},
      {field: "hal", header: "Hal şəkilçisi"},
      {field: "mensubiyyet", header: "Mənsubiyyət şəkilçisi"},
      {field: "sexs", header: "Şəxs şəkilçisi"},
      {field: "hissecik", header: "Hissəcik"},
      {field: "izah", header: "Sözün izahı"}
    ],
    Feil: [
      {field: "soz", header: "Söz"},
      {field: "nov", header: "Feilin növü"},
      {field: "zaman", header: "Zaman şəkilçisi"},
      {field: "hissecik", header: "Hissəcik"},
      {field: "sexs", header: "Şəxs şəkilçisi"},
      {field: "izah", header: "Sözün izahı"}
    ]
  }
  selectedNitq:any;
  nitq_result: any;
  res:any;

  constructor(private http: DjangoService, private primengConfig: PrimeNGConfig) {
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.lang = localStorage.getItem('lang') || 'az';
    this.submitted = false;
    if(this.metn_result == '') {
      this.submitted = false;
    }
  }

  metnGonder(result: any): void {
    const tm = new TextModel(result, '');
    this.submitted = true;
    this.http.sendText(tm, this.name)
      .subscribe((result) => {
        console.log(result);
        this.metn_result = result;
        this.metn_result = this.metn_result.data;
      },
        (error) => {
          console.log(error);
        });
  }

  sozGonder(result: any): void {
    const sm = new TextModel(result, '');
    this.submitted = true;
    this.http.sendText(sm, this.name)
      .subscribe((result) => {
        console.log(result);
        this.soz_result = result;
        this.soz_result = this.soz_result.data;
      },
        (error) => {
          console.log(error);
        });
  }

  nitqGonder(result: any, nitq:string): void {
    console.log(nitq);
    this.SELECTED_NITQ = nitq;
    const tm = new TextModel(result, nitq);
    this.submitted = true;
    this.http.sendText(tm, this.name)
      .subscribe((result) => {
        this.nitq_result = result;
        this.nitq_result = this.nitq_result.cvb;
        console.log(this.nitq_result);
      },
        (error) => {
          console.log(error);
        });
  }

}
